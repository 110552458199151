








































































































































import { Vue, Component } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { groupBy, orderBy, sortBy } from 'lodash';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ConversationService from '@/services/crm/conversation.service';
import ConversationModel from '@/models/crm/conversation.model';
import CrmChatListItem from '@/components/crm/chat-list-item.vue';
import ConversationTemplateModel from '@/models/crm/conversation-template.model';
import UserModel from '@/models/user.model';
import { ConversationMessageOriginEnum } from '@/enums/crm/conversation-message-origin.enum';
import { ConversationMessageStatusEnum } from '@/enums/crm/conversation-message-status.enum';
import ConversationUserPermissionsModel from '@/models/crm/conversation-user-permissions.model';
import ConversationDepartmentModel from '@/models/crm/conversation-department.model';
import SettingsService from '@/services/crm/settings.service';
import SettingsModel from '@/models/crm/settings.model';

interface IActiveGroup {
  attendant: UserModel;
  items: IGroupItem[];
}

interface IQueueGroup {
  department: ConversationDepartmentModel;
  items: IGroupItem[];
}

interface IGroupItem {
  id?: number;
  conversation: ConversationModel;
  unreadCounter?: number;
  loading?: boolean;
}

@Component({
  components: {
    CrmChatListItem,
  },
})
export default class CrmChatOverview extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmConversationService)
  protected conversationService!: ConversationService;

  private settings!: SettingsModel;

  conversationUserPermission!: ConversationUserPermissionsModel;

  tab: string | null = null;

  openChatList: ConversationModel[] = [];

  groupedOpenChatList: IActiveGroup[] = [];

  queueChatList: ConversationModel[] = [];

  groupedQueueList: IQueueGroup[] = [];

  templates: ConversationTemplateModel[] = [];

  attendants: UserModel[] = [];

  refreshing = false;

  filterOpenChatAttendants = false;

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    try {
      this.settings = await this.settingsService.getSettings();
      await Promise.all([this.loadTemplates(), this.loadAttendants(), this.loadUserPermissions()]);

      if (!this.conversationUserPermission.masterCrmUser) {
        this.$notify.error(this.$t('global.youDoNotHavePermissionToAccessTheScreen'));

        this.$router.back();

        return;
      }

      await Promise.all([this.loadActiveChats(), this.loadQueueChats()]);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  onChatItemClick(item: IGroupItem): void {
    this.$store.commit('openConversation', item.conversation, { root: true });
  }

  onClickPrevious(): void {
    this.$router.back();
  }

  async loadActiveChats(): Promise<void> {
    this.openChatList = await this.conversationService.getOpenConversations();

    const groupedOpenChatList: IActiveGroup[] = [];

    const groupedList = groupBy(this.openChatList, (x) => x.atendente?.id);
    const sortedGroupedList = sortBy(groupedList, (item) => item[0].atendente?.nome);

    Object.keys(sortedGroupedList).forEach((id) => {
      let items: IGroupItem[] = sortedGroupedList[id].map((x) => {
        const conversation = x;
        conversation.messages = [];
        const item = {
          conversation,
        };
        return item;
      });

      items = items
        .sort((a, b) => {
          let dataA!: Date;
          let dataB!: Date;

          if (a.conversation.dataUltimaMensagem) {
            dataA = new Date(a.conversation.dataUltimaMensagem);
          } else {
            dataA = new Date(a.conversation.dataInicio);
          }
          if (b.conversation.dataUltimaMensagem) {
            dataB = new Date(b.conversation.dataUltimaMensagem);
          } else {
            dataB = new Date(b.conversation.dataInicio);
          }
          return +dataB - +dataA;
        });

      const group: IActiveGroup = {
        attendant: items[0].conversation.atendente,
        items,
      };

      groupedOpenChatList.push(group);

      this.loadMessages(group);
    });

    // Add other attendants without open attending to list
    sortBy(this.attendants, 'nome').forEach((attendant) => {
      if (groupedOpenChatList.find((x) => x.attendant.id === attendant.id)) {
        return;
      }
      // if (attendant.nome.startsWith('ibtech') || attendant.nome.startsWith('geovendas')) {
      //   return;
      // }

      const group: IActiveGroup = {
        attendant,
        items: [],
      };

      groupedOpenChatList.push(group);
    });

    this.groupedOpenChatList = [...groupedOpenChatList];
  }

  async loadQueueChats(): Promise<void> {
    this.queueChatList = await this.conversationService.getOnHoldConversations();

    const groupedQueueList: IQueueGroup[] = [];

    const groupedList = groupBy(this.queueChatList, (x) => x.departamento?.id);
    const sortedGroupedList = sortBy(groupedList, (item) => item[0].departamento?.nome);

    Object.keys(sortedGroupedList).forEach((id) => {
      const items: IGroupItem[] = sortedGroupedList[id].map((x) => {
        const conversation = x;
        conversation.messages = [];
        const item = {
          conversation,
        };
        return item;
      });

      const group: IQueueGroup = {
        department: items[0].conversation.departamento,
        items,
      };

      groupedQueueList.push(group);

      this.loadMessages(group);
    });

    this.groupedQueueList = [...groupedQueueList];
  }

  async onRefresh(): Promise<void> {
    this.refreshing = true;
    try {
      await Promise.all([this.loadActiveChats(), this.loadQueueChats()]);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.refreshing = false;
    }
  }

  get inAttendanceCount(): number {
    return this.openChatList.length;
  }

  get onHoldCount(): number {
    return this.queueChatList.length;
  }

  private async loadMessages(group: IActiveGroup | IQueueGroup): Promise<void> {
    group.items.forEach(async (x) => {
      const item = x;
      item.loading = true;
      try {
        const messages = orderBy(
          await this.conversationService.getConversationMessages(item.conversation.waConversationId, true),
          ['dataUltimaMensagem'],
          ['asc'],
        );

        item.conversation.messages = messages;
        item.id = item.conversation.id;
        item.unreadCounter = messages.reduce((accumulated, message) => {
          const incoming = message.origin.id === ConversationMessageOriginEnum.Incoming;
          const received = message.status.code === ConversationMessageStatusEnum.Received;

          return accumulated + (incoming && received ? 1 : 0);
        }, 0);
      } finally {
        item.loading = false;
      }
    });
  }

  private async loadTemplates(): Promise<void> {
    this.templates = await this.conversationService.getTemplates(false);
  }

  private async loadAttendants(): Promise<void> {
    this.attendants = await this.conversationService.getAttendants();
  }

  private async loadUserPermissions(): Promise<void> {
    this.conversationUserPermission = await this.conversationService.getUserPermissions();
  }
}
